// Formata um valor para reais
export function FormatToReal(param) {
	let moneyFormatter = new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
		minimumFractionDigits: 2,
	});

	return moneyFormatter.format(param);
}

// Converte o instituto para sua área
export function ConvertInstitutoToArea(instituto) {
	return instituto === "ICCT"
		? "criatividade e tecnologia"
		: instituto === "ICHS"
		? "humanidades e sociais"
		: instituto === "ICS"
		? "saúde"
		: null;
}

// Converte uma opção de curso para seu formato de exibição
export function ConvertCursoToOpcoes(tipo, modalidade) {
	return `${ConvertModalidadeToDisplay(tipo)} - ${ConvertModalidadeToDisplay(
		modalidade
	)}`;
}

export function ConvertModalidadeToDisplay(modalidade) {
	return modalidade
		.replace("Hibrido", "Híbrido")
		.replace("Tecnologo", "Tecnólogo");
}

// Função de normalização dos dados de string.
export function NormalizeString(input) {
	if (typeof input === "undefined" || input === null) return null;

	return input
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();
}
