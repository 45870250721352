import React from "react";
import { Link } from "gatsby";
import {
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBBadge,
	MDBCardTitle,
	MDBContainer,
	MDBCol,
	MDBRow,
	MDBIcon,
} from "mdbreact";
import PropTypes from "prop-types";
import { GGgetMode } from "../../services/gglocalstorage";

/**
 * Botão de inscrições para os cursos de pós-graduação.
 * @param {string} slug - Slug da página.
 * @param {string} instituto - Instituto do curso.
 * @param {object} opcoes - Opções do curso.
 * @param {string} name - Nome do curso.
 */

export default function CardCurso({
	slug,
	name,
	image,
	opcoes,
	instituto,
	className,
}) {
	CardCurso.propTypes = {
		slug: PropTypes.string.isRequired,
		opcoes: PropTypes.node.isRequired,
		name: PropTypes.string.isRequired,
		instituto: PropTypes.string,
		image: PropTypes.string,
		className: PropTypes.string,
	};

	let urlCurso = `/cursos/${slug}/`;

	let corTag =
		instituto == "criatividade e tecnologia"
			? "icct-background text-primary"
			: instituto == "saúde"
			? "ics-background"
			: "ichs-background";

	return (
		<Link to={urlCurso}>
			<MDBCard
				className={
					className +
					" h-100 my-2 mx-2 " +
					(GGgetMode() && "border border-secondary")
				}
				style={{
					backgroundColor: "#ebebeb",
					color: GGgetMode() && "white",
					border:"2px solid #303030",
					borderRadius:"0px"
				}}
			>
				<div className="mx-2 mt-3 text-center">
					<MDBBadge color="secondary" className={`mx-1 p-1 ${corTag}`}>
						<span>{instituto[0].toUpperCase() + instituto.substring(1)}</span>
					</MDBBadge>
					{opcoes?.map((opcao) => {
						const opcaoRenderizada = opcao.replace(/\bHíbrido\b/g, "Presencial");
						return (
							<MDBBadge
								color="transparent"
								key={opcao}
								className={
									(GGgetMode() ? "border-default" : " border-secondary") +
									" mx-1 p-1 border"
								}
							>
								<span className={GGgetMode() ? "text-default" : "text-secondary"}>
									{opcaoRenderizada}
								</span>
							</MDBBadge>
						);
					})}
				</div>
				<h4
					className={
						
						" text-center pt-4 my-auto pb-1"
					}
					style={{color:"#303030"}}
				>
					{name}
				</h4>
				<MDBContainer>
					<MDBRow>
						<MDBCol col={12}>
							<div
								className={
									(GGgetMode() ? "text-white" : " text-primary") +
									" mb-3 align-text-bottom text-right"
								}
							>
								<MDBIcon icon="arrow-right" size="2x" />
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBCard>
		</Link>
	);
}

/*<div
key={element.Title}
style={{ width: "22rem" }}
className="my-2"
>*/
