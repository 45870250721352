import * as React from "react";
import {
	MDBCard,
	MDBCardText,
	MDBCardBody,
	MDBCardImage,
	MDBRow,
	MDBCol,
	MDBContainer,
	MDBView,
	MDBBox,
} from "mdbreact";
import PropTypes from "prop-types";

import SEO from "../components/SEO/Seo";
import FilterCursos from "../components/FiltroCurso/FiltroCurso";

// Import image for explaine card of courses modes
import DigitalEADImage from "../assets/images/cursos/digiatal-ead.png";
import HibridaImage from "../assets/images/cursos/hibrida.png";
import PresencialImage from "../assets/images/cursos/presencial.png";
import QuizImage from "../assets/images/cursos/quiz.png";

import Layout from "../Layouts/MasterLayout/MasterLayout";

import GGHeaderImage from "../assets/gg/images/cursos/headerCursos.png";
import BgVestibular from "../assets/gg/images/home/bgVestibular.png";

import { GGgetMode } from "../services/gglocalstorage";

import logoQuiz from "../assets/gg/images/home/logoQuiz.png";

export default function Cursos({ pageContext: { cursos } }) {
	Cursos.propTypes = {
		pageContext: PropTypes.object.isRequired,
		cursos: PropTypes.array,
	};

	return (
		<Layout color="#ebebeb">
			<SEO
				title="Cursos"
				description="Conheça os cursos de graduação presencial, híbrida e EAD da Universidade Feevale. Inscrições abertas."
			/>

			{GGgetMode() ? (
				<MDBBox bgColor="primary">
					<MDBContainer fluid className="m-0 p-0">
						<MDBRow className="m-0 p-0">
							<MDBCol
								style={{
									backgroundImage: `url(${BgVestibular})`,
									backgroundSize: "cover",
								}}
							>
								<img
									src={GGHeaderImage}
									className="img-fluid mx-auto d-block"
									style={{ maxHeight: 600 }}
									alt="mais de 60 opções de cursos para jogar"
								/>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBBox>
			) : (
				<MDBContainer>
					<MDBRow>
						<MDBCol className="text-center">
							<h2 className="h1-responsive font-weight-bold text-center my-5 text-secondary text-uppercase">
								Nossos cursos
							</h2>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			)}

			{/* {!GGgetMode() && (
				<MDBContainer className="pb-3">
					<MDBRow>
						<MDBCol md="4" className="py-2">
							<MDBCard className="h-100">
								<MDBCardImage
									className="card-img-top"
									src={PresencialImage}
									waves={false}
								/>
								<MDBCardBody className="text-center">
									<MDBCardText className="text-primary">
										Tudo que a experiência presencial tem de melhor! Na Feevale,
										o estudante é protagonista e aprende com práticas que o
										colocam em situações próximas do mercado. Além disso, dispõe
										de uma infraestrutura de excelência, com laboratórios e
										espaços de aprendizagem de alta qualidade.
									</MDBCardText>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
						<MDBCol md="4" className="py-2">
							<MDBCard className="h-100">
								<MDBView>
									<MDBCardImage
										className="card-img-top"
										src={HibridaImage}
										waves={false}
									/>
								</MDBView>
								<MDBCardBody className="text-center">
									<MDBCardText className="text-primary">
										A combinação perfeita entre o presencial e o digital! São
										cursos dinâmicos e atuais, organizados por módulos. Todo o
										conteúdo do semestre disponível no ambiente digital, aliado
										a experiências presenciais semanalmente.
									</MDBCardText>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
						<MDBCol md="4" className="py-2">
							<MDBCard className="h-100">
								<MDBView>
									<MDBCardImage
										className="card-img-top"
										src={DigitalEADImage}
										waves={false}
									/>
								</MDBView>
								<MDBCardBody className="text-center">
									<MDBCardText className="text-primary">
										Garanta a qualidade Feevale onde você estiver! Cursos
										pensados nos mínimos detalhes para proporcionar a melhor
										experiência de aprendizagem. Faça o seu caminho em qualquer
										lugar do mundo.
									</MDBCardText>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			)} */}
			<MDBContainer>
				{GGgetMode() ? (
					<a
						href="https://way.feevale.br/quiz/"
						target="_blank"
						rel="noreferrer"
					>
						<MDBRow className="py-5">
							<MDBCol col={12} md={6} className="text-md-right text-center">
								<img src={logoQuiz} />
							</MDBCol>
							<MDBCol
								col={12}
								md={6}
								className="text-white text-center text-md-left pt-3"
							>
								<h2>
									<span className="font-weight-bold">
										<p>Não sabe</p>
										<p>
											<span style={{ color: "#00d2ff" }}>
												qual classe jogar
											</span>
											?
										</p>
									</span>
									<p>
										<span
											style={{ backgroundColor: "#ff1e00" }}
											className="px-2"
										>
											Bora de quiz!
										</span>
									</p>
								</h2>
							</MDBCol>
						</MDBRow>
					</a>
				) : (
					<MDBRow>
						{/* <MDBCol>
							<a
								href="https://way.feevale.br/quiz/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={QuizImage}
									alt="Quiz vocacional Feevale"
									className="img-fluid"
								/>
							</a>
						</MDBCol> */}
					</MDBRow>
				)}
			</MDBContainer>
			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<FilterCursos cursos={cursos} />
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
